<template>
	<div class="order_statistics">
		<div class="tit">订单数据统计</div>
		<div class="search_content">
			时间选择：
			<div class="mode">
				<div class="mode_item" :class="active_mode == 0 ? 'active' : ''" @click="changeDate(0)">近7天</div>
				<div class="mode_item" :class="active_mode == 1 ? 'active' : ''" @click="changeDate(1)">近30天</div>
				<div class="mode_item" :class="active_mode == 3 ? 'active' : ''" @click="chooseTime()">自定义
					<el-date-picker ref="timePick" @change="changeTimeLimit" v-model="date_limit" type="daterange"  value-format="timestamp" clear-icon>
					</el-date-picker>
				</div>
			</div>
		</div>
		<div class="info">
			<div class="item">
				<div class="top">订单金额总数</div>
				<div class="middle">
					<div class="money">{{totalInfo.order_total || 0}}</div>
				</div>
			</div>
			<div class="item">
				<div class="top">订单总数</div>
				<div class="middle">
					<div class="money">{{totalInfo.order_pay_count || 0}}</div>
				</div>
			</div>
			<div class="item">
				<div class="top">订单商品总数</div>
				<div class="middle">
					<div class="money">{{totalInfo.goods_pay_count || 0}}</div>
				</div>
			</div>
		</div>
		<div class="data_show">
			<div id="order_stat" class="order_stat"></div>
		</div>
		
	</div>
</template>

<script>
	import { MemberStatList ,StatTotal} from '@/api/statistics.js';
	export default {
		data() {
			return {
				start_time:'',
				end_time:'',
				date_limit:'',
				member_stat:{},
				active_mode:0,
				show_time1:'',
				show_time2:'',
				totalInfo:{}
			}
		},
		created() {
			this.getTime();
			this.getTotal()
		},
		methods: {
			getTotal(){
				StatTotal({
					start_time:this.start_time,
					end_time:this.end_time+86399,
				})
				.then(res=>{
					if(res.code>=0){
						this.totalInfo = res.data
					}
				})
			},
			changeDate(mode){
				this.active_mode = mode;
				this.getTime();
			},
			changeTimeLimit(e){
				if(e){
					this.start_time = e[0]/1000;
					this.end_time = e[1]/1000;
					this.show_time1 = this.$util.timeStampTurnTime(this.start_time).split(' ')[0]
					this.show_time2 = this.$util.timeStampTurnTime(this.end_time).split(' ')[0]
					this.getMemberStatList();
					this.getTotal()
				}
			},
			getTime(){
				if(this.active_mode == 0){
					var curTime = parseInt(new Date().getTime() / 1000);
					var startDate = parseInt(curTime - (6 * 3600 * 24));
					this.start_time = startDate;
					this.end_time = curTime;
					this.show_time2 = this.$util.timeStampTurnTime(curTime).split(' ')[0];
					this.show_time1 = this.$util.timeStampTurnTime(startDate).split(' ')[0];
				}else{
					var curTime = parseInt(new Date().getTime() / 1000);
					var startDate = parseInt(curTime - (30 * 3600 * 24));
					this.start_time = startDate;
					this.end_time = curTime;
					this.show_time2 = this.$util.timeStampTurnTime(curTime).split(' ')[0];
					this.show_time1 = this.$util.timeStampTurnTime(startDate).split(' ')[0];
				}
				this.getMemberStatList();
				this.getTotal()
			},
			chooseTime(){
				this.active_mode = 3;
				if (this.$refs.timePick.$el) this.$refs.timePick.$el.click();
			},
			getEcharts(){
				var myChart = this.$echarts.init(document.getElementById('order_stat'));
				var option = {
					grid: {
					    top: '20px',
					    left: '0',
					    right: '0',
					    bottom: '0',
					    containLabel: true
					},
					dataZoom: [{
						type: 'inside', //1平移 缩放
						throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
						start: 1, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
						end: this.member_stat.time.length > 20 ? 25 : 80, //数据窗口范围的结束百分比。范围是：0 ~ 100。
						zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
					}],
				    tooltip: {
				        trigger: 'axis',
				    },
				    xAxis: [
				        {
				            type: 'category',
				            data: this.member_stat.time,
				            position: 'bottom',
							axisLabel: {
							   interval: 0,
				            }
				        }
				    ],
				    yAxis: {
				        type: 'value',
				    },
				    series: [
				        {
							name: '订单金额',
				            data: this.member_stat.order_total,
				            type: 'line',
				            connectNulls: true,
				        },
				        {
							name: '订单数',
				            data: this.member_stat.order_pay_count,
				            type: 'line',
				            connectNulls: true,
				        },
						{
							name: '订单商品数',
						    data: this.member_stat.goods_pay_count,
						    type: 'line',
						    connectNulls: true,
						},
				    ],
				};
				myChart.setOption(option);
			},
			
			getMemberStatList() {
				MemberStatList({
					start_time:this.start_time,
					end_time:this.end_time+86399,
				})
				.then(res=>{
					if(res.code>=0){
						this.member_stat = res.data;
						this.getEcharts();
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	.order_statistics{
		background-color: #fff;
		padding: 18px 25px 18px 18px;
		.tit{
			color: #333;
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 18px;
		}
		.search_content{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.mode{
				display: flex;
				align-items: center;
				margin-left: 15px;
				.mode_item{
					min-width: 71px;
					box-sizing: border-box;
					text-align: center;
					cursor: pointer;
					font-size: 14px;
					line-height: 16px;
					padding: 6px 10px;
					border: 1px solid #E0E0E0;
					&:first-child,
					&:nth-child(2){
						border-right: none;
					}
					&.active{
						border-color: #00A98F;
						background-color: #00A98F;
						color: #fff;
					}
				}
			}
			.mode_item{
				position: relative;
				.el-date-editor{
					position: absolute;
					bottom: 0;
					border-radius: 0;
					height: 0;
					width: 0;
					padding: 0;
					border:none;
					.el-range__icon{
						display: none;
					}
					.el-range-separator{
						display: none;
					}
					.el-range-separator{
						line-height: 20px;
					}
					.el-input__icon{
						line-height: 1;
					}
					.el-range-input{
						border: none;
						color: #333;
						font-size: 14px;
						line-height: 20px;
						padding: 0;
					}
				}
				input{
					border: 1px solid #E0E0E0;
					outline: none;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					padding: 8px 12px;
					margin-right: 12px;
				}
				input::placeholder{
					color: #888;
					font-size: 14px;
					line-height: 20px;
				}
			}
			
			.btn{
				color: #FFF;
				font-size: 12px;
				line-height: 16px;
				padding: 6px 18px;
				border-radius: 2px;
				background-color: #00A98F;
				margin-left: 15px;
				cursor: pointer;
			}
		}
		.data_show{
			width: 100%;
			height: 500px;
			.order_stat{
				height: 100%;
			}
		}
	}
	
	.info{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		.item{
			width: calc( ( 100% - 24px ) / 2 );
			background-color: #F7F7F7;
			padding: 18px 29px 19px 20px;
			margin-right: 24px;
			box-sizing: border-box;
			margin-bottom: 24px;
			.top{
				color: #333;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 18px;
			}
			.middle{
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-bottom: 24px;
				.tit{
					display: flex;
					align-items: center;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					margin-bottom: 10px;
					img{
						width: 14px;
						height: 14px;
						display: block;
						margin-left: 5px;
					}
				}
				.money{
					color: #00A98F;
					font-size: 26px;
					line-height: 36px;
				}
			}
			.total_data{
				display: flex;
				align-items: center;
				justify-content: space-between;
				div{
					color: #333;
					font-size: 12px;
					line-height: 17px;
				}
			}
			&:nth-child(2n){
				margin-right: 0;
				margin-bottom: 0;
			}
		}
	}
</style>